import React, { useState, useEffect, useRef } from 'react';
import {LocalVideoTrack} from "twilio-video";

interface Props {
  localVideoTrack: LocalVideoTrack
}

export default function OwnTrack(props: Props) {

  const videoRef: any = useRef(null);

  if (typeof props.localVideoTrack !== "undefined") {
    console.log(props.localVideoTrack.dimensions)
    props.localVideoTrack.attach(videoRef.current)


  }

  return (
    <>
      <div
        id="videoCallMain" style={{...style, position: 'absolute'}}>
        <video style={videostyle} ref={videoRef} autoPlay />
      </div>
    </>
  )
}

const style = {
  backgroundColor: 'rgb(35, 39, 49)',
  border: "4px solid #FFFFFF",
  boxShadow: "0 0 20px 0 rgba(0,0,0,0.40)",
  borderRadius: 8,
  bottom: 32,
  right: 32,
  // overflow: 'hidden'
}

const videostyle = {
  // borderRadius: 4,
  height: 180
}
