import * as React from "react";
import Videocall from "./views/index";
import { useEffect } from "react";
import {
  connect,
  createLocalTracks,
  createLocalVideoTrack,
  LocalTrack,
  LocalVideoTrack,
  RemoteParticipant,
  RemoteVideoTrack,
  Room
} from "twilio-video";
import ControlElements from "./views/ControlElements";

interface State {
  connected: boolean;
  localVideoTrack: LocalVideoTrack | undefined;
  remoteVideoTrack: RemoteVideoTrack | undefined;
  room: Room | undefined;
  cameraSwitchEnabled: boolean;
}

export default class VideoCall extends React.PureComponent<any, any> {
  state = {
    connected: false,
    currentVideoInputIndex: 0,
    localVideoTrack: undefined,
    remoteVideoTrack: undefined,
    room: undefined,
    cameraSwitchEnabled: false
  };

  componentDidMount(): void {
    // const data = fetch("/video/token", {
    //   method: "POST",
    //   body: JSON.stringify({
    //     identity: Math.random().toString(),
    //     room: "Hallo"
    //   }),
    //   headers: {
    //     "Content-Type": "application/json"
    //   }
    // }).then(res => res.json());

    let token: string;
    let name;

    console.log(navigator.appVersion)
    if (navigator.appVersion === "5.0 (Macintosh; Intel Mac OS X 10_15_2) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/79.0.3945.130 Safari/537.36") {
      console.log("MAC")
      token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzY1ZWFmM2Q2NTZkODg3MDI4MzgxOGIxMTgzMjJiNDRkLTE1ODAyMTkyMzYiLCJpc3MiOiJTSzY1ZWFmM2Q2NTZkODg3MDI4MzgxOGIxMTgzMjJiNDRkIiwic3ViIjoiQUM1MzdlMGM5ZGVlNWRiOWIwMmI2NTE4YjU0YjVkNDNhNCIsImV4cCI6MTU4MDIyMjgzNiwiZ3JhbnRzIjp7ImlkZW50aXR5IjoiZGFuaWVsIiwidmlkZW8iOnt9fX0.jfHG4gEXdA7IRwM_1SqClJnstwwKGeWbRTzlvsDOsc0"
      name = "MAC";
    } else {
      console.log("HUAWEI")
      token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzY1ZWFmM2Q2NTZkODg3MDI4MzgxOGIxMTgzMjJiNDRkLTE1ODAyMjA2NjciLCJpc3MiOiJTSzY1ZWFmM2Q2NTZkODg3MDI4MzgxOGIxMTgzMjJiNDRkIiwic3ViIjoiQUM1MzdlMGM5ZGVlNWRiOWIwMmI2NTE4YjU0YjVkNDNhNCIsImV4cCI6MTU4MDIyNDI2NywiZ3JhbnRzIjp7ImlkZW50aXR5IjoiZGFuaWVsIiwidmlkZW8iOnt9fX0.CNZ2lQWDrnpAMxeejZgUP45acdmS2HH6vMWzhDpm2Gc"
      name = "HUAWEI"
    }


    createLocalTracks({
      audio: true,
      video: true
    }).then((localTracks: LocalTrack[]) => {
      console.log("LOCAL TRACKS");
      const localVideoTrack = localTracks.find(
        localTrack => localTrack.kind === "video"
      );
      this.setState({
        localVideoTrack: localVideoTrack
      });

      connect(
        token,
        {
          name: "ROOM",
          audio: true,
          video: true,
          dominantSpeaker: true,
          tracks: localTracks
        }
      ).then(
        room => {
          this.setState({ room: room, connected: true });
          console.log(`Successfully joined a Room: ${room}`);

          room.on("participantConnected", participant => {
            console.log(`A remote Participant connected: ${participant}`);
          });

          room.on("dominantSpeakerChanged", (participant: RemoteParticipant) => {
            console.log("The new dominant speaker in the Room is:", participant);
            this.setState({
              remoteVideoTrack: participant.videoTracks
            });
          });

          console.log(room);
        },
        error => {
          //TODO: Error handling (permissions denied)
          console.error(error);
          console.error(`Unable to connect to Room: ${error.message}`);
        }
      );


    });

    navigator.mediaDevices.enumerateDevices().then(devices => {
      const videoInputs = devices.filter(
        device => device.kind === "videoinput"
      );
      if (videoInputs.length > 1) {
        this.setState({
          cameraSwitchEnabled: true
        });
      } else {
        this.setState({
          cameraSwitchEnabled: false
        });
      }
    });
  }

  private onClickSwitchCamera = () => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      const videoInputs = devices.filter(
        device => device.kind === "videoinput"
      );
    });
  };

  private onClickMuteMicrophone = () => {

    console.log(this.state.room)
    if (this.state.room !== undefined) {
    }
  };

  private onClickLeave = () => {
    console.log(this.state.room);
    if (this.state.room !== undefined) {
      console.log(this.state.room);
    }
  };

  render() {
    return (
      <>
        <Videocall
          enableCameraSwitch={this.state.cameraSwitchEnabled}
          onClickSwitchCamera={this.onClickSwitchCamera}
          onClickMuteMicrophone={this.onClickMuteMicrophone}
          onClickLeave={this.onClickLeave}
          localVideoTrack={this.state.localVideoTrack}
          remoteVideoTrack={this.state.remoteVideoTrack}
        />
      </>
    );
  }
}
