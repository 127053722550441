import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import VideoCall from '../src/container/Videocall/index.tsx'


function App() {
  return (
    <div className="App">
      {/*<header className="App-header">*/}
      {/*  <img src={logo} className="App-logo" alt="logo" />*/}
      {/*  <p>*/}
      {/*    Edit <code>src/App.js</code> and save to reload.*/}
      {/*  </p>*/}
      {/*  <a*/}
      {/*    className="App-link"*/}
      {/*    href="https://reactjs.org"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    Learn React*/}
      {/*  </a>*/}
      {/*</header>*/}
      <ul>
        <li>
          <Link to="/consultation/jeanette-koepsel">Jeanette Koepsel</Link>
        </li>
      </ul>
      <Switch>
        <Route path="/consultation/jeanette-koepsel">
          <VideoCall />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
