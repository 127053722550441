import * as React from "react";
import OwnTrack from "./OwnTrack";
import { useEffect } from "react";
import {connect, RemoteVideoTrack} from "twilio-video";
import ControlElements from "./ControlElements";
import RemoteTrack from "./RemoteTrack";

interface Props {
  remoteVideoTrack: RemoteVideoTrack | undefined
  localVideoTrack: any;
  enableCameraSwitch: boolean
  onClickSwitchCamera: () => void;
  onClickMuteMicrophone: () => void;
  onClickLeave: () => void;
}

export default function Videocall(props: Props) {
  console.log(props);

  return (
    <>
      <h1>Test</h1>
      <div  style={{ ...style, position: "relative" }}>
        <ControlElements
          enableCameraSwitch={props.enableCameraSwitch}
          onClickSwitchCamera={props.onClickSwitchCamera}
          onClickMuteMicrophone={props.onClickMuteMicrophone}
          onClickLeave={props.onClickLeave}
          style={{ ...controlElementsStyle }}
        />
        {/*<RemoteTrack remoteVideoTrack={props.remoteVideoTrack}  />*/}
        <OwnTrack localVideoTrack={props.localVideoTrack} />
      </div>
    </>
  );
}

const style = {
  backgroundColor: "#232731",
  height: "100vh",
  width: "100vw"
};

const controlElementsStyle = {
  position: "absolute",
  top: 32,
  right: 32,

};
