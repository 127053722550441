import React, { useState, useEffect, useRef } from "react";
import { LocalVideoTrack } from "twilio-video";
import IconCamera from "../../../assets/images/icons/icon_camera_switch.svg";
import IconLeave from "../../../assets/images/icons/icon_leave.svg";
import IconMute from "../../../assets/images/icons/icon_mute.svg";

interface Props {
  style?: any;
  enableCameraSwitch: boolean
  onClickSwitchCamera: () => void;
  onClickMuteMicrophone: () => void;
  onClickLeave: () => void;
}

export default function ControlElements(props: Props) {
  return (
    <div style={{ ...props.style }}>
      {props.enableCameraSwitch && <img
        style={{ ...Icon, ...IconTop }}
        src={IconCamera}
        onClick={props.onClickSwitchCamera}
      />}
      <img
        style={{ ...Icon, ...IconTop }}
        src={IconMute}
        onClick={props.onClickMuteMicrophone}
      />
      <img
        style={{ ...Icon, ...IconBottom }}
        src={IconLeave}
        onClick={props.onClickLeave}
      />
    </div>
  );
}

const Icon = {
  cursor: "pointer"
};

const IconTop = {
  display: "block",
  marginBottom: 16
};

const IconBottom = {
  display: "block",
  marginBottom: 16,
  marginTop: 32
};
